/**
 * @author Mayura Pradeep Patil
 * @email mayura.pradeepa.patil@rockwellcollins.com
 * @create date 2022-02-14 12:44:03
 * @modify date 2022-02-14 12:44:03
 * @desc Tap Test zone flag
 */


/**
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
// eslint-disable-next-line no-underscore-dangle
(window as any).__Zone_disable_customElements = true;
